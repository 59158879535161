export function ModeBlur(props: { mode: "none" | "audio" | "video" }) {
	return (
		<svg
			data-mode={props.mode}
			class="absolute -bottom-40 left-[-100px] hidden w-[calc(100%+200px)] overflow-hidden fill-slate-300/60 blur-[200px] transition-colors data-[mode=audio]:fill-blue-400/50 data-[mode=video]:fill-purple-400/50 sm:flex"
			viewBox="0 0 400 75"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="400" height="75" fill-opacity={0} />
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0 0.671022L0 67.0001H400V0.671021C339.314 14.352 271.523 22 200 22C128.477 22 60.6861 14.352 0 0.671022Z"
			/>
		</svg>
	);
}
