/* @refresh reload */
import { render } from "solid-js/web";

import './index.css'
import App from './App'
import { ColorModeProvider, ColorModeScript, createLocalStorageManager } from "@kobalte/core";
import {DEV} from "solid-js";
import * as Sentry from "@sentry/browser";

if (!DEV) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_URL,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.8,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ["localhost", import.meta.env.VITE_SHUFFLE_SERVER_URL],

        // Capture Replay for 10% of all sessions,
        // plus 100% of sessions with an error
        replaysOnErrorSampleRate: 1.0
    });
}

const root = document.getElementById("root");
const storageManager = createLocalStorageManager("vite-ui-theme");
render(
	() => (
		<>
			<ColorModeScript storageType={storageManager.type} />
			<ColorModeProvider storageManager={storageManager}>
				<App />
			</ColorModeProvider>
		</>
	),
	root!,
);
