import axios from "axios";
import { createEffect, createSignal, Match, Show, Switch } from "solid-js";
import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogTitle,
} from "~/components/ui/alert-dialog.tsx";
import { Checkbox } from "~/components/ui/checkbox.tsx";
import { Input } from "~/components/ui/input.tsx";
import { Label } from "~/components/ui/label.tsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs.tsx";
import { DarkModeToggle } from "./components/darkModeToggle";
import { ModeBlur } from "./components/modeBlur";
import { Button } from "./components/ui/button";

const allowedMimeTypes = ["audio/wav", "audio/mpeg", "video/avi", "video/mp4", "video/mov"] as const;

function App() {
	const [file, setFile] = createSignal<File | null>(null);
	const [mode, setMode] = createSignal<Mode>();
	const [options, setOptions] = createSignal<Options>({ chunks: 64, bpm: 120, randomChunks: false });
	const [status, setStatus] = createSignal<"idle" | "uploading" | "downloading" | "waiting">("idle");
	const [errorAlert, setErrorAlert] = createSignal("");
	const [progress, setProgress] = createSignal(0);

	createEffect(() => {
		if (options().chunks < 2) setOptions({ ...options(), chunks: 2 });
		if (options().bpm < 1) setOptions({ ...options(), bpm: 1 });
	});

	function getRequestUrl(): URL {
		const host = import.meta.env.DEV
			? `http://${new URL(document.URL).hostname}:3000`
			: import.meta.env.VITE_SHUFFLE_SERVER_URL;

		if (mode() === "video:byChunks") {
			const url = new URL("video", host);
			url.searchParams.append("chunks", options().chunks.toString());
			return url;
		}

		const url = new URL("audio", host);

		if (mode() === "audio:byChunks") {
			url.searchParams.append("mode", "byChunks");
			url.searchParams.append("chunks", options().chunks.toString());
			url.searchParams.append("random_chunk_size", options().randomChunks.toString());
		} else {
			url.searchParams.append("mode", "byBpm");
			url.searchParams.append("bpm", options().bpm.toString());
		}

		return url;
	}

	async function uploadFile() {
		if (file() === null) return;
		const formData = new FormData();
		formData.append("file", file() as File);

		setStatus("uploading");

		// As much as I dislike using Axios, it's the only way to get upload progress besides XHR...
		const res = await axios({
			method: "POST",
			url: getRequestUrl().toString(),
			data: formData,
			responseType: "arraybuffer", // We can either get a string or a blob, axios is unintelligent, and you can't get both at the same time
			onUploadProgress: (progressEvent) => {
				setProgress(Math.round((progressEvent.loaded / (progressEvent?.total ?? file.length)) * 100));

				if (progressEvent.loaded === progressEvent.total) {
					setStatus("waiting");
					setProgress(0);
				}
			},
			onDownloadProgress: (downloadEvent) => {
				if (status() === "waiting") setStatus("downloading");
				setProgress(Math.round((downloadEvent.loaded / (downloadEvent?.total ?? file.length)) * 100));

				if (downloadEvent.loaded === downloadEvent.total) {
					setStatus("idle");
					setProgress(0);
				}
			},
			validateStatus: () => true, // We handle errors ourselves
		});

		setStatus("idle");

		if (res.status !== 200) {
			if (res.status === 413) {
				setErrorAlert("The file is too large. The maximum size is 100MB.");
			} else {
				const decoder = new TextDecoder("utf-8");
				const errorText = decoder.decode(new Uint8Array(res.data));
				setErrorAlert(errorText ?? "Something went wrong. Please try again later.");
			}
			return;
		}

		const blob = new Blob([res.data], { type: file()!.type });
		const nameStrings = file()!.name.split(".");
		nameStrings.splice(-1, 1); // Get rid of the file extension
		downloadBlob(
			blob,
			`${nameStrings.join(".")}-shuffled.${mimeToExtension[file()!.type as keyof typeof mimeToExtension]}`,
		);
	}

	function dropHandler(e: DragEvent) {
		e.preventDefault();
		if (e.dataTransfer === null) return;

		if (e.dataTransfer.items) {
			if (e.dataTransfer.items[0].kind === "file") {
				const file = e.dataTransfer.items[0].getAsFile();

				/* @ts-ignore */
				if (allowedMimeTypes.includes(file!!.type)) {
					setMode(file?.type.includes("audio") ? "audio:byChunks" : "video:byChunks");
					setFile(file);
				} else setErrorAlert("Only WAV files are supported");
			}
		} else {
			/* @ts-ignore */
			if (e.dataTransfer.files.length > 0 && allowedMimeTypes.includes(e.dataTransfer.files[0].type)) {
				setMode(e.dataTransfer.files[0]?.type.includes("audio") ? "audio:byChunks" : "video:byChunks");
				setFile(e.dataTransfer.files[0]);
			}
		}
	}

	return (
		<>
			<div class="flex justify-between p-2 pt-2.5">
				<div>
					<h1 class="text-xl font-semibold">AudioShuffle</h1>
					<Show when={mode() !== undefined}>
						<p
							data-mode={mode()?.split(":")[0]}
							class="font-medium data-[mode=audio]:text-blue-500 data-[mode=video]:text-purple-500"
						>
							{mode()!!.includes("audio") ? "Audio" : "Video"} mode
						</p>
					</Show>
				</div>
				<DarkModeToggle />
			</div>
			<div class="flex h-full w-full flex-col justify-center justify-self-center p-2 sm:w-[32rem] sm:p-0">
				<div class="mb-2 grid w-full grid-cols-[0.65fr,0.35fr] justify-between gap-2 border-b-[1.5px] pb-4 sm:grid-cols-[0.8fr,0.2fr]">
					<div
						id="dropzone"
						class="cursor-pointer overflow-hidden text-ellipsis rounded-md border border-input p-2 text-center"
						onClick={(e) => {
							e.preventDefault();
							document.querySelector<HTMLInputElement>("#fileInput")!.click();
						}}
						onDrop={dropHandler}
						onDragOver={(e) => e.preventDefault()}
					>
						<Show when={file() !== null} fallback="Cick or drag and drop a file here">
							{file()!.name}
						</Show>
					</div>
					<input
						id="fileInput"
						type="file"
						accept={allowedMimeTypes.join(",")}
						name="file"
						class="invisible hidden"
						onChange={(e) => {
							const file = e.target.files ? e.target.files[0] : null;
							setMode(file?.type.includes("audio") ? "audio:byChunks" : "video:byChunks");
							setFile(file);
						}}
						hidden
					/>
					<Button
						class="h-full"
						onClick={() => uploadFile()}
						disabled={file() === null || options().bpm < 1 || options().chunks < 2}
					>
						<Switch fallback="Shuffle">
							<Match when={status() === "idle"}>Shuffle</Match>
							<Match when={status() === "uploading"}>Uploading {progress()}%</Match>
							<Match when={status() === "downloading"}>Downloading {progress()}%</Match>
							<Match when={status() === "waiting"}>Shuffling</Match>
						</Switch>
					</Button>
				</div>
				<Switch
					fallback={
						<p class="text-pretty text-center">
							Select an audio or video file first to get started. You'll be able to adjust the settings
							later.
						</p>
					}
				>
					<Match when={mode()?.includes("audio")}>
						<h2 class="font-medium">Divide by:</h2>
						<Tabs defaultValue="audio:byChunks" onChange={(v) => setMode(v as Mode)}>
							<TabsList class="grid w-full grid-cols-2">
								<TabsTrigger value="audio:byChunks" onClick={() => setMode("audio:byChunks")}>
									Chunks
								</TabsTrigger>
								<TabsTrigger value="audio:byBpm" onClick={() => setMode("audio:byBpm")}>
									Beats per minute
								</TabsTrigger>
							</TabsList>
							<TabsContent value="audio:byChunks" class="flex flex-col gap-4">
								<div>
									<Label for="chunks">Amount of chunks</Label>
									<Input
										type="number"
										id="chunks"
										placeholder="Chunks"
										min={2}
										value={options().chunks}
										onChange={(e) => setOptions({ ...options(), chunks: e.target.valueAsNumber })}
									/>
									<p class="text-sm text-muted-foreground">
										Best results are achieved with even numbers - less chance for corruption.
									</p>
								</div>
								<div class="items-top flex space-x-2">
									<Checkbox
										id="randomChunks"
										checked={options().randomChunks}
										onChange={(randomChunks) => setOptions({ ...options(), randomChunks })}
									/>
									<div class="grid gap-1.5 leading-none">
										<Label for="randomChunks-input">Random chunks</Label>
										<p class="text-sm text-muted-foreground">
											Randomize the size of chunks. The total amount of chunks will be the same.
										</p>
									</div>
								</div>
							</TabsContent>
							<TabsContent value="audio:byBpm">
								<Label for="bpm">Beats per minute</Label>
								<Input
									type="number"
									id="bpm"
									placeholder="Bpm"
									min={1}
									value={options().bpm}
									onChange={(e) => setOptions({ ...options(), bpm: e.target.valueAsNumber })}
								/>
							</TabsContent>
						</Tabs>
					</Match>
					<Match when={mode()?.includes("video")}>
						<div class="flex flex-col gap-4">
							<p class="rounded-md border-l-4 border-warning-foreground bg-warning p-2 pl-4 text-xs text-warning-foreground sm:text-sm">
								Videos are still experimental, expect corruption/noise. Warning - the video output will
								have flashing images! The process may take a while.
							</p>
							<div>
								<Label for="chunks">Amount of chunks</Label>
								<Input
									type="number"
									id="chunks"
									placeholder="Chunks"
									min={2}
									value={options().chunks}
									onChange={(e) => setOptions({ ...options(), chunks: e.target.valueAsNumber })}
								/>
							</div>
						</div>
					</Match>
				</Switch>
			</div>

			<footer class="z-10 w-full border-t border-slate-600 bg-white/50 p-2 text-center text-xs backdrop-blur dark:border-white/60 dark:bg-neutral-950/50 md:text-base">
				Video shuffling is real! (kind of). Uploaded files are never stored on the server.
			</footer>
			<AlertDialog open={errorAlert() !== ""} onOpenChange={() => setErrorAlert("")}>
				<AlertDialogContent>
					<AlertDialogTitle>Sorry, something went wrong</AlertDialogTitle>
					<AlertDialogDescription>{errorAlert()}</AlertDialogDescription>
				</AlertDialogContent>
			</AlertDialog>
			<ModeBlur mode={(mode()?.split(":")[0] as "audio" | "video") ?? "none"} />
		</>
	);
}

type Options = { chunks: number; bpm: number; randomChunks: boolean };
type Mode = "audio:byChunks" | "audio:byBpm" | "video:byChunks";

const mimeToExtension = {
	"audio/mpeg": "mp3",
	"audio/wav": "wav",
	"video/avi": "avi",
	"video/mp4": "mp4",
	"video/mov": "mov",
} satisfies Record<(typeof allowedMimeTypes)[number], string>;

function downloadBlob(blob: Blob, filename: string) {
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = filename;
	a.click();
	URL.revokeObjectURL(url);
}

export default App;
